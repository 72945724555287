@charset "UTF-8";
/*
    Font mixins
*/
/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
  margin: 0;
  padding: 0; }

header, footer, nav, section, article, hgroup, figure {
  display: block; }

ul, li {
  list-style: none; }

body {
  background-color: #000; }

.row {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media (min-width: 420px) {
    .row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media (min-width: 1024px) {
    .row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media (min-width: 1400px) {
    .row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  .row.row-rtl .col-xs-1, .row.row-rtl .col-sm-1, .row.row-rtl .col-md-1, .row.row-rtl .col-lg-1, .row.row-rtl .col-xs-2, .row.row-rtl .col-sm-2, .row.row-rtl .col-md-2, .row.row-rtl .col-lg-2, .row.row-rtl .col-xs-3, .row.row-rtl .col-sm-3, .row.row-rtl .col-md-3, .row.row-rtl .col-lg-3, .row.row-rtl .col-xs-4, .row.row-rtl .col-sm-4, .row.row-rtl .col-md-4, .row.row-rtl .col-lg-4, .row.row-rtl .col-xs-5, .row.row-rtl .col-sm-5, .row.row-rtl .col-md-5, .row.row-rtl .col-lg-5, .row.row-rtl .col-xs-6, .row.row-rtl .col-sm-6, .row.row-rtl .col-md-6, .row.row-rtl .col-lg-6, .row.row-rtl .col-xs-7, .row.row-rtl .col-sm-7, .row.row-rtl .col-md-7, .row.row-rtl .col-lg-7, .row.row-rtl .col-xs-8, .row.row-rtl .col-sm-8, .row.row-rtl .col-md-8, .row.row-rtl .col-lg-8, .row.row-rtl .col-xs-9, .row.row-rtl .col-sm-9, .row.row-rtl .col-md-9, .row.row-rtl .col-lg-9, .row.row-rtl .col-xs-10, .row.row-rtl .col-sm-10, .row.row-rtl .col-md-10, .row.row-rtl .col-lg-10, .row.row-rtl .col-xs-11, .row.row-rtl .col-sm-11, .row.row-rtl .col-md-11, .row.row-rtl .col-lg-11, .row.row-rtl .col-xs-12, .row.row-rtl .col-sm-12, .row.row-rtl .col-md-12, .row.row-rtl .col-lg-12 {
    float: right; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media (min-width: 420px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 48rem; } }
  @media (min-width: 1024px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 64rem; } }
  @media (min-width: 1400px) {
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 120rem; } }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0%; }

@media (min-width: 420px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.33333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.33333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1024px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.33333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.33333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1400px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.33333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.33333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table; }

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both; }

main {
  min-height: 100vh; }

_:-ms-fullscreen, :root main {
  float: left; }

body {
  overflow-y: scroll;
  overflow-x: hidden; }

*:focus {
  outline: 0; }

.barba-container {
  overflow-x: hidden; }

.container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 420px) {
    .container {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 48rem; } }
  @media (min-width: 1024px) {
    .container {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 64rem; } }
  @media (min-width: 1400px) {
    .container {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 120rem; } }

.container-full {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto; }
  .container-full div[class^="col-"] {
    max-width: 100%; }

.container[id], .container-full[id] {
  margin-top: 40px; }
  @media (min-width: 1024px) {
    .container[id], .container-full[id] {
      margin-top: 90px; } }

@media only screen and (min-width: 768px) {
  header, footer, main {
    margin-right: 50px;
    margin-left: 50px; } }

@media only screen and (min-width: 1024px) {
  header, footer, main {
    margin-right: 100px;
    margin-left: 100px; } }

html.overflow, body.overflow {
  overflow: hidden; }

.particle {
  height: auto;
  width: 100%;
  position: relative; }
  .particle img {
    position: relative;
    width: 100%;
    height: auto; }
  .particle canvas {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    animation-delay: 0.5s;
    animation: fadeCanvas 1s;
    touch-action: manipulation !important;
    pointer-events: all !important; }

@keyframes fadeCanvas {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.boxtest {
  position: relative;
  height: 300px;
  width: 100%;
  background: red; }

body,
html {
  font-family: "NoeText-Book", serif;
  font-size: 16px;
  font-weight: normal;
  color: #FFF; }

.invert-text {
  color: #000; }

.background-black {
  background-color: #000; }

.richtext h1 {
  font-family: "NoeText-Book", serif; }
  @media (min-width: 1024px) {
    .richtext h1 {
      font-family: "NoeText-Bold", serif; } }

.richtext h2 {
  font-family: "NoeText-Bold", serif; }

.richtext h3 {
  font-family: "NoeText-Bold", serif; }

.richtext h4 {
  font-family: "NoeText-Bold", serif; }

.richtext h5 {
  font-family: "BrandonGrotesque-Bold", sans-serif; }

.richtext .lead, .richtext .beer-law-text p, .beer-law-text .richtext p, .richtext .error-text p, .error-text .richtext p {
  font-family: "NoeText-Bold", serif; }

.richtext p {
  font-family: "NoeText-Book", serif;
  padding-bottom: 1rem; }
  @media (min-width: 1024px) {
    .richtext p {
      font-family: "BrandonGrotesque-Medium", sans-serif; } }

.richtext .small {
  font-family: "NoeText-Book", serif; }

.richtext a {
  font-family: "NoeText-Book", serif;
  color: #FFF;
  text-decoration: none; }
  .richtext a:hover {
    text-decoration: underline; }

.richtext button {
  font-family: "NoeText-Bold", serif; }

.h1, .richtext h1,
h1 {
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Book", serif; }
  @media (min-width: 420px) {
    .h1, .richtext h1,
    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .h1, .richtext h1,
    h1 {
      font-size: 7.5rem;
      line-height: 8.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .h1, .richtext h1,
    h1 {
      font-size: 7.5rem;
      line-height: 8.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .h1, .richtext h1,
    h1 {
      font-family: "NoeText-Bold", serif; } }

.h2, .richtext h2,
h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Bold", serif; }
  @media (min-width: 420px) {
    .h2, .richtext h2,
    h2 {
      font-size: 1.875rem;
      line-height: 2.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .h2, .richtext h2,
    h2 {
      font-size: 3.75rem;
      line-height: 4.5rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .h2, .richtext h2,
    h2 {
      font-size: 3.75rem;
      line-height: 4.5rem;
      letter-spacing: 0rem; } }

.h3, .richtext h3,
h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Bold", serif; }
  @media (min-width: 420px) {
    .h3, .richtext h3,
    h3 {
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .h3, .richtext h3,
    h3 {
      font-size: 2.25rem;
      line-height: 3rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .h3, .richtext h3,
    h3 {
      font-size: 2.25rem;
      line-height: 3rem;
      letter-spacing: 0rem; } }

.h4, .richtext h4,
h4 {
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Bold", serif; }
  @media (min-width: 420px) {
    .h4, .richtext h4,
    h4 {
      font-size: 0.9375rem;
      line-height: 0.9375rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .h4, .richtext h4,
    h4 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .h4, .richtext h4,
    h4 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      letter-spacing: 0rem; } }

.h5, .richtext h5,
h5 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "BrandonGrotesque-Bold", sans-serif; }
  @media (min-width: 420px) {
    .h5, .richtext h5,
    h5 {
      font-size: 1.125rem;
      line-height: 1.875rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .h5, .richtext h5,
    h5 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      letter-spacing: 0.01875rem; } }
  @media (min-width: 1400px) {
    .h5, .richtext h5,
    h5 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      letter-spacing: 0.01875rem; } }

.lead, .richtext .lead, .beer-law-text p, .error-text p {
  font-size: 1.25rem;
  line-height: 1.5625rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Bold", serif; }
  @media (min-width: 420px) {
    .lead, .richtext .lead, .beer-law-text p, .error-text p {
      font-size: 1.25rem;
      line-height: 1.5625rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .lead, .richtext .lead, .beer-law-text p, .error-text p {
      font-size: 2.5rem;
      line-height: 3.1875rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .lead, .richtext .lead, .beer-law-text p, .error-text p {
      font-size: 2.5rem;
      line-height: 3.1875rem;
      letter-spacing: 0rem; } }

.nav, .nav-list ul li a, .navigation ul.main li a,
.navigation ul.main li span, .navigation ul.second .title a,
.navigation ul.second .title span {
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Bold", serif; }
  @media (min-width: 420px) {
    .nav, .nav-list ul li a, .navigation ul.main li a,
    .navigation ul.main li span, .navigation ul.second .title a,
    .navigation ul.second .title span {
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .nav, .nav-list ul li a, .navigation ul.main li a,
    .navigation ul.main li span, .navigation ul.second .title a,
    .navigation ul.second .title span {
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .nav, .nav-list ul li a, .navigation ul.main li a,
    .navigation ul.main li span, .navigation ul.second .title a,
    .navigation ul.second .title span {
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 0rem; } }

.p, ul, li, .richtext p, .nav-list .info,
p {
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 1rem;
  font-family: "NoeText-Book", serif; }
  @media (min-width: 420px) {
    .p, ul, li, .richtext p, .nav-list .info,
    p {
      font-size: 1rem;
      line-height: 1.625rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .p, ul, li, .richtext p, .nav-list .info,
    p {
      font-size: 1.1875rem;
      line-height: 1.6875rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .p, ul, li, .richtext p, .nav-list .info,
    p {
      font-size: 1.1875rem;
      line-height: 1.6875rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .p, ul, li, .richtext p, .nav-list .info,
    p {
      font-family: "BrandonGrotesque-Medium", sans-serif; } }

.small, .richtext .small {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Book", serif; }
  @media (min-width: 420px) {
    .small, .richtext .small {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .small, .richtext .small {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .small, .richtext .small {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0rem; } }

.link, .richtext a,
a {
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Book", serif;
  color: #FFF;
  text-decoration: none; }
  @media (min-width: 420px) {
    .link, .richtext a,
    a {
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .link, .richtext a,
    a {
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .link, .richtext a,
    a {
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: 0rem; } }
  .link:hover, .richtext a:hover,
  a:hover {
    text-decoration: underline; }

.button, .richtext button,
button {
  font-size: 1.125rem;
  line-height: 1.1875rem;
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NoeText-Bold", serif; }
  @media (min-width: 420px) {
    .button, .richtext button,
    button {
      font-size: 1.125rem;
      line-height: 1.1875rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1024px) {
    .button, .richtext button,
    button {
      font-size: 1.5rem;
      line-height: 1.8125rem;
      letter-spacing: 0rem; } }
  @media (min-width: 1400px) {
    .button, .richtext button,
    button {
      font-size: 1.5rem;
      line-height: 1.8125rem;
      letter-spacing: 0rem; } }

img {
  width: 100%;
  height: auto; }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms; }

.ratio-custom {
  background: #ccc; }
  .ratio-custom iframe,
  .ratio-custom img,
  .ratio-custom video {
    width: 100%;
    height: auto; }

.ratio-auto {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
  background: #000; }
  .ratio-auto iframe,
  .ratio-auto img,
  .ratio-auto video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block; }

.main-logo {
  margin: 0 auto;
  text-align: center;
  display: table;
  position: relative;
  z-index: 99; }
  @media (min-width: 1024px) {
    .main-logo {
      display: inline-block; } }

.logo {
  height: auto;
  pointer-events: none;
  display: inline-block; }
  .logo svg {
    pointer-events: none;
    width: 120px;
    height: auto;
    margin: 0 auto;
    display: block; }
  @media (min-width: 1024px) {
    .logo {
      width: 10rem; }
      .logo svg {
        pointer-events: none;
        width: 227px;
        height: 119px; } }

.button, .richtext button {
  display: inline-block;
  width: 226px;
  height: 226px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #000;
  position: relative;
  text-decoration: none; }
  .button:hover, .richtext button:hover {
    text-decoration: none !important; }
    .button:hover .button--arrow svg, .richtext button:hover .button--arrow svg {
      left: 10px; }
  @media (min-width: 1024px) {
    .button, .richtext button {
      transform: scale(1); } }
  .button svg, .richtext button svg {
    display: block;
    margin: 0 auto; }
  .button .button--icon, .richtext button .button--icon, .button .button--text, .richtext button .button--text, .button .button--arrow, .richtext button .button--arrow {
    background-color: transparent; }
  .button .button--text, .richtext button .button--text {
    margin: 0 auto;
    text-align: center;
    width: 80%;
    padding-bottom: 16px; }
    .button .button--text span, .richtext button .button--text span {
      font-family: "NoeText-Bold", serif;
      font-size: 30px; }
      @media (min-width: 1024px) {
        .button .button--text span, .richtext button .button--text span {
          font-size: 24px; } }
  .button .button--arrow svg, .richtext button .button--arrow svg {
    position: relative;
    left: 0;
    width: 29px;
    height: 17px;
    transition: all 0.3s; }
  .button.bar .button--icon, .richtext button.bar .button--icon {
    padding: 30px 70px 24px; }
  .button.brewery .button--icon, .richtext button.brewery .button--icon {
    padding: 9px 61px 20px; }
  .button.beer .button--icon, .richtext button.beer .button--icon {
    padding: 30px 70px 25px; }

.icon {
  display: inline-block;
  width: 40px;
  height: 40px; }
  .icon svg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .icon-arrow-link:hover svg {
    left: calc(50% + 10px); }
  .icon-arrow-link svg {
    width: 30px;
    height: 17px;
    fill: #FFF;
    transition: all 0.3s; }
  .icon-link svg {
    width: 16px;
    height: 15px;
    fill: #FFF;
    transition: all 0.3s; }
  .icon-close svg {
    width: 24px;
    height: 24px;
    fill: #FFF; }
  .icon-download svg {
    width: 19px;
    height: 17px; }
    .icon-download svg use {
      fill: #FFF; }

.debuger {
  display: block;
  position: absolute;
  padding: 2rem;
  opacity: 0.9;
  background: red;
  color: white;
  font-weight: bold; }
  .debuger:after {
    content: "mobile"; }
  @media (min-width: 420px) {
    .debuger {
      background: blue; }
      .debuger:after {
        content: "tablet"; } }
  @media (min-width: 1024px) {
    .debuger {
      background: pink;
      color: black; }
      .debuger:after {
        content: "desktop"; } }
  @media (min-width: 1400px) {
    .debuger {
      background: yellow;
      color: black; }
      .debuger:after {
        content: "large desktop"; } }

.desktop-nav {
  display: none; }
  @media (min-width: 1024px) {
    .desktop-nav {
      display: block; } }

.mobile-nav {
  display: block;
  padding-top: 2rem;
  padding-left: 12px !important; }
  .mobile-nav-hidden {
    display: none; }
  .mobile-nav ul,
  .mobile-nav ul.second {
    padding-top: 0 !important;
    position: relative !important; }
  @media (min-width: 1024px) {
    .mobile-nav {
      display: none; } }

.nav-icon {
  cursor: pointer;
  position: absolute;
  top: 2.2rem;
  right: 1.5rem;
  z-index: 99;
  height: 2rem;
  width: 2rem; }
  .nav-icon .offset {
    padding-top: 6px; }
  .nav-icon .icon-menu,
  .nav-icon .icon-menu {
    display: none; }
    .nav-icon .icon-menu.open,
    .nav-icon .icon-menu.open {
      display: block; }
  @media (min-width: 1024px) {
    .nav-icon {
      display: none; } }

.nav-list {
  left: 0;
  background: black;
  z-index: 1000;
  position: absolute;
  width: 100vw;
  height: 100%; }
  .nav-list.open {
    display: block; }
  .nav-list.close {
    display: none; }
  .nav-list ul {
    padding-top: 4rem;
    padding-bottom: 0; }
    .nav-list ul li {
      text-align: center;
      padding-top: 0.1rem;
      padding-bottom: 4rem; }
  .nav-list .info {
    display: block;
    text-align: center;
    line-height: 1rem; }

.navigation {
  padding-left: 48px;
  position: relative; }
  .navigation ul.main {
    position: absolute;
    list-style: none;
    padding-top: 20vh;
    width: 15rem;
    min-width: 15rem;
    display: inline-block;
    z-index: 999; }
    .navigation ul.main li {
      padding-bottom: 3.0rem;
      line-height: 3.5rem; }
      .navigation ul.main li a,
      .navigation ul.main li span {
        text-decoration: none;
        transition: all 0.5s ease;
        display: block; }
        .navigation ul.main li a:hover,
        .navigation ul.main li span:hover {
          text-decoration: none;
          font-size: 45px; }
      .navigation ul.main li ul {
        position: relative; }
        .navigation ul.main li ul li a {
          padding-left: 1rem; }
  .navigation ul.second {
    position: absolute;
    list-style: none;
    padding-top: 20vh;
    width: 15rem;
    min-width: 15rem;
    display: inline-block;
    margin-left: 0;
    z-index: 999;
    opacity: 1; }
    .navigation ul.second .title {
      position: relative;
      padding-bottom: 1.25rem;
      display: block;
      line-height: 3.5rem; }
      .navigation ul.second .title a,
      .navigation ul.second .title span {
        position: relative;
        text-decoration: none;
        transition: all 0.5s ease; }
        .navigation ul.second .title a:hover,
        .navigation ul.second .title span:hover {
          text-decoration: none;
          transition: all 0.5s ease; }
          .navigation ul.second .title a:hover svg,
          .navigation ul.second .title span:hover svg {
            transform: scale(1.1);
            transition: all 0.5s ease; }
        .navigation ul.second .title a .icon-nav,
        .navigation ul.second .title span .icon-nav {
          height: 2rem;
          width: 2rem;
          position: absolute;
          left: -3.5rem;
          cursor: pointer;
          z-index: 99; }
          .navigation ul.second .title a .icon-nav svg,
          .navigation ul.second .title span .icon-nav svg {
            transition: all 0.5s ease;
            margin-top: 0.55rem !important;
            position: absolute;
            top: 0; }
    .navigation ul.second li {
      padding-bottom: 1.5rem; }
      .navigation ul.second li a {
        font-family: "BrandonGrotesque-Bold", sans-serif;
        text-transform: uppercase; }

.anim-desktop {
  display: none; }
  @media (min-width: 1024px) {
    .anim-desktop {
      display: block; } }

.anim-mobile {
  display: block;
  padding-bottom: 15vh; }
  @media (min-width: 1024px) {
    .anim-mobile {
      display: none; } }
  .anim-mobile h2 {
    padding: 2.5rem 1rem 2rem;
    text-align: center; }
  .anim-mobile .beer-text {
    padding-top: 0 !important; }
  .anim-mobile .beer-animation-container {
    display: block !important;
    position: relative;
    animation: fadein 0.8s; }
  .anim-mobile .static {
    height: 50vh;
    position: relative; }
  .anim-mobile .batch {
    display: inline-block;
    position: relative;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100px) scale(1.75);
    padding-top: 0; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1 !important; } }

.header-container {
  position: relative; }
  @media (min-width: 1024px) {
    .header-container {
      position: absolute;
      top: 0;
      height: 100vh;
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: 0; } }
  .header-container .beer-animation-container {
    display: none;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1); }
    @media (min-width: 1024px) {
      .header-container .beer-animation-container {
        display: block;
        left: 85%;
        top: 15%;
        transform: translate(-85%, -15%) scale(1); } }
    @media screen and (min-width: 1620px) {
      .header-container .beer-animation-container {
        display: block;
        left: 70%;
        top: 20%;
        transform: translate(-70%, -20%) scale(1.2); } }
    @media screen and (min-width: 1920px) {
      .header-container .beer-animation-container {
        display: block;
        left: 70%;
        top: 30%;
        transform: translate(-70%, -30%) scale(1.4); } }
    .header-container .beer-animation-container .beer-warp-1 {
      background-image: url("/assets/images/parts.png"), radial-gradient(circle, rgba(240, 226, 63, 0.1) 18%, rgba(255, 244, 0, 0.2) 26%, rgba(0, 0, 0, 0) 36%);
      background-size: 800px 800px;
      height: 800px;
      width: 800px;
      position: absolute;
      opacity: 0;
      transform: scale(1);
      pointer-events: none; }
    .header-container .beer-animation-container .beer-warp-2 {
      background-image: url("/assets/images/parts.png"), radial-gradient(circle, rgba(240, 214, 63, 0.1) 15%, rgba(255, 244, 0, 0.2) 25%, rgba(0, 0, 0, 0) 36%);
      background-size: 800px 800px;
      height: 800px;
      width: 800px;
      position: absolute;
      opacity: 0;
      transform: scale(1) rotate(180deg);
      pointer-events: none; }
    .header-container .beer-animation-container .beer-warp-3 {
      background-image: url("/assets/images/lense.png"), radial-gradient(circle, rgba(240, 214, 63, 0.3) 10%, rgba(255, 244, 0, 0.01) 20%, rgba(0, 0, 0, 0) 36%);
      background-size: 800px 800px;
      height: 800px;
      width: 800px;
      position: absolute;
      opacity: 0;
      transform: scale(1);
      pointer-events: none; }
    .header-container .beer-animation-container .inner-box-beer {
      position: relative;
      background-image: url("/assets/images/beer_bottom.png");
      background-size: 950px 950px;
      width: 950px;
      height: 950px; }
      .header-container .beer-animation-container .inner-box-beer .beer-text {
        padding-top: 2.0rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        text-align: center; }
        .header-container .beer-animation-container .inner-box-beer .beer-text:hover .icon {
          padding-left: 0.5rem; }
        .header-container .beer-animation-container .inner-box-beer .beer-text:hover .link, .header-container .beer-animation-container .inner-box-beer .beer-text:hover .richtext a, .richtext .header-container .beer-animation-container .inner-box-beer .beer-text:hover a {
          transform: scale(1.02); }
        .header-container .beer-animation-container .inner-box-beer .beer-text .intro .title {
          pointer-events: none;
          display: block;
          font-size: 36px;
          line-height: 47px;
          font-family: "NoeText-Bold", serif;
          text-align: center; }
        .header-container .beer-animation-container .inner-box-beer .beer-text .intro .icon {
          position: relative;
          top: -12px;
          padding-top: 0;
          transition: all 0.2s; }
        .header-container .beer-animation-container .inner-box-beer .beer-text .intro .link, .header-container .beer-animation-container .inner-box-beer .beer-text .intro .richtext a, .richtext .header-container .beer-animation-container .inner-box-beer .beer-text .intro a {
          padding-top: 1.0rem;
          font-family: "NoeText-Bold", serif;
          font-size: 50px;
          line-height: 55px;
          transition: all 0.2s; }
          @media (min-width: 1024px) {
            .header-container .beer-animation-container .inner-box-beer .beer-text .intro .link, .header-container .beer-animation-container .inner-box-beer .beer-text .intro .richtext a, .richtext .header-container .beer-animation-container .inner-box-beer .beer-text .intro a {
              padding-top: 2.8rem;
              font-size: 24px !important; } }
          .header-container .beer-animation-container .inner-box-beer .beer-text .intro .link:hover, .header-container .beer-animation-container .inner-box-beer .beer-text .intro .richtext a:hover, .richtext .header-container .beer-animation-container .inner-box-beer .beer-text .intro a:hover {
            text-decoration: none; }
      .header-container .beer-animation-container .inner-box-beer .linktext {
        display: block; }
  .header-container .partholder {
    display: inline-flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    z-index: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.56) 10%, rgba(0, 0, 0, 0.8) 27%, rgba(0, 0, 0, 0.97) 50%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 90%); }
    .header-container .partholder canvas {
      z-index: 2; }
    .header-container .partholder .placeholder-inner {
      z-index: 1;
      display: block;
      width: 500px;
      height: 500px; }

.header-container.header-beer-bottles {
  height: 50vh; }
  @media (min-width: 420px) {
    .header-container.header-beer-bottles {
      height: 65vh; } }
  @media (min-width: 1024px) {
    .header-container.header-beer-bottles {
      height: 100vh; } }

.header-container .header-beer {
  width: 55vw;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -60%) scale(1); }
  .header-container .header-beer canvas {
    display: none; }
  @media (min-width: 1024px) {
    .header-container .header-beer {
      left: 60%;
      top: 50%;
      transform: translate(-60%, -50%) scale(1); }
      .header-container .header-beer canvas {
        display: block; } }
  @media screen and (min-width: 1620px) {
    .header-container .header-beer {
      background: red;
      left: 60%;
      top: 50%;
      transform: translate(-60%, -50%) scale(1); } }
  @media screen and (min-width: 1920px) {
    .header-container .header-beer {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1); } }
  .header-container .header-beer .alcoholfree {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    .header-container .header-beer .alcoholfree .alcoholfree-item {
      height: 100vh;
      width: 100%;
      margin: 0 auto; }
  .header-container .header-beer .headerintro1,
  .header-container .header-beer .headerintro2,
  .header-container .header-beer .headerintro3 {
    position: absolute; }
    .header-container .header-beer .headerintro1.beer:hover img,
    .header-container .header-beer .headerintro2.beer:hover img,
    .header-container .header-beer .headerintro3.beer:hover img {
      transition: transform 0.3s ease-out;
      transform: scale(1.01); }
    .header-container .header-beer .headerintro1.beer img,
    .header-container .header-beer .headerintro2.beer img,
    .header-container .header-beer .headerintro3.beer img {
      height: 180px;
      width: auto;
      transition: transform 0.3s ease-out; }
      @media (min-width: 420px) {
        .header-container .header-beer .headerintro1.beer img,
        .header-container .header-beer .headerintro2.beer img,
        .header-container .header-beer .headerintro3.beer img {
          height: 40vh; } }
      @media (min-width: 1024px) {
        .header-container .header-beer .headerintro1.beer img,
        .header-container .header-beer .headerintro2.beer img,
        .header-container .header-beer .headerintro3.beer img {
          height: 55vh; } }
    .header-container .header-beer .headerintro1 img,
    .header-container .header-beer .headerintro2 img,
    .header-container .header-beer .headerintro3 img {
      height: auto;
      width: 25rem; }
  .header-container .header-beer .headerintro1 {
    top: 50%;
    left: 15%;
    z-index: 9;
    opacity: 0;
    transform: translate(-45%, -50%) rotate(-10deg) scale(0.5);
    animation: FADE-LEFT 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    animation-delay: 0s; }
    @media (min-width: 1400px) {
      .header-container .header-beer .headerintro1 {
        left: 35%; } }
  .header-container .header-beer .headerintro2 {
    top: 50%;
    left: 50%;
    z-index: 10;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
    animation: FADE-CENTER 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    animation-delay: 0s; }
  .header-container .header-beer .headerintro3 {
    top: 50%;
    left: 85%;
    z-index: 9;
    opacity: 0;
    transform: translate(-65%, -50%) rotate(10deg) scale(0.5);
    animation: FADE-RIGHT 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    animation-delay: 0s; }
    @media (min-width: 1400px) {
      .header-container .header-beer .headerintro3 {
        left: 65%; } }

@keyframes FADE-LEFT {
  0% {
    opacity: 0;
    transform: translate(-45%, -50%) rotate(-10deg) scale(0.5); }
  100% {
    opacity: 1;
    transform: translate(-40%, -50%) rotate(0deg) scale(1); } }

@keyframes FADE-CENTER {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.5); }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2); } }

@keyframes FADE-RIGHT {
  0% {
    opacity: 0;
    transform: translate(-65%, -50%) rotate(4deg) scale(0.5); }
  100% {
    opacity: 1;
    transform: translate(-60%, -50%) rotate(0deg) scale(1); } }

.batch {
  display: none;
  position: absolute;
  left: 90%;
  top: 80%;
  transform: translate(-90%, -80%);
  cursor: pointer;
  z-index: 99;
  position: absolute;
  width: 220px;
  height: 220px; }
  @media (min-width: 1024px) {
    .batch {
      display: block; } }
  @media (min-width: 1024px) {
    .batch {
      display: block;
      left: 95%;
      top: 75%;
      transform: translate(-95%, -75%); } }
  @media screen and (min-width: 1620px) {
    .batch {
      display: block;
      left: 90%;
      top: 70%;
      transform: translate(-90%, -70%); } }
  @media screen and (min-width: 1920px) {
    .batch {
      display: block;
      left: 93%;
      top: 70%;
      transform: translate(-93%, -70%); } }
  .batch:hover .button--arrow {
    transition: all 0.2s;
    padding-left: 0.5rem; }
  .batch canvas {
    z-index: -1; }
  .batch a {
    display: block; }
  .batch .button, .batch .richtext button, .richtext .batch button {
    pointer-events: none; }

.header-container.image-home {
  display: block;
  position: absolute;
  transform: scale(4);
  left: 0;
  right: 0;
  width: 100vw;
  z-index: -1; }
  @media (min-width: 1024px) {
    .header-container.image-home {
      display: block;
      transform: scale(1);
      z-index: -1; } }

.header-container .header-image {
  overflow-x: hidden;
  pointer-events: none;
  position: relative;
  z-index: -1;
  right: 0;
  top: 1rem; }
  @media (min-width: 1024px) {
    .header-container .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; } }
  .header-container .header-image::before {
    z-index: -1;
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200%;
    height: 200%;
    content: " ";
    z-index: 11;
    background: radial-gradient(circle, transparent 53%, #000 84%); }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .header-container .header-image img {
    animation: fadein 0.8s;
    z-index: -10;
    height: auto;
    width: 100%;
    right: 0; }
    @media (min-width: 1024px) {
      .header-container .header-image img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover; } }

footer {
  background: #000; }
  footer.beer-law-footer {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px; }
    @media only screen and (min-width: 768px) {
      footer.beer-law-footer {
        padding-left: 60px;
        padding-right: 60px; } }
    @media (min-width: 1024px) {
      footer.beer-law-footer {
        padding-left: 100px;
        padding-right: 100px; } }
  footer .footer-navigation {
    display: none; }
  footer .addressblocks {
    padding: 40px 20px;
    background-color: #000; }
    footer .addressblocks p {
      font-family: "BrandonGrotesque-Medium", sans-serif;
      font-size: 17px;
      line-height: 24px; }
    footer .addressblocks a p {
      padding: 0; }
  footer .sm-icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px; }
    footer .sm-icons .sm-icon {
      display: block;
      margin-right: 10px; }
      footer .sm-icons .sm-icon img {
        width: 30px;
        height: 30px; }
  footer .google-map {
    position: relative;
    left: -20px;
    width: 100vw;
    height: 100vw;
    background: none; }
  @media (min-width: 420px) {
    footer .google-map {
      height: 420px; } }
  @media only screen and (min-width: 768px) {
    footer .google-map {
      left: -140px;
      width: 110vw; } }
  @media (min-width: 1024px) {
    footer {
      padding-top: 60px; }
      footer .footer-navigation {
        display: block;
        background-color: #000; }
        footer .footer-navigation h3 {
          padding-bottom: 60px; }
        footer .footer-navigation li {
          display: inline-block;
          vertical-align: top;
          margin: 0 -2px;
          width: 35%; }
          footer .footer-navigation li:last-child {
            width: 30%; }
          footer .footer-navigation li a {
            display: block;
            padding-left: 40px; }
        footer .footer-navigation .footer-nav-subitem {
          display: block;
          width: 100%;
          padding-bottom: 0; }
          footer .footer-navigation .footer-nav-subitem:last-child {
            width: 100%; }
      footer .footer-bottom-wrapper {
        position: relative; }
      footer .addressblocks {
        position: relative;
        display: inline-block;
        margin: 0 -2px;
        padding: 70px 40px;
        z-index: 2;
        background: transparent; }
      footer .google-map-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1; }
        footer .google-map-wrapper:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 30%;
          height: 100%;
          background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0));
          z-index: 2; }
        footer .google-map-wrapper:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 30%;
          height: 100%;
          background-image: linear-gradient(to left, black, rgba(0, 0, 0, 0));
          z-index: 2; }
        footer .google-map-wrapper .google-map {
          left: 0;
          width: 100%;
          height: 100%; } }

header {
  padding-top: 1.5rem;
  height: auto; }
  @media (min-width: 1024px) {
    header {
      padding-top: 10vh;
      height: 100vh; } }

.combination-links .link-intern {
  background: red; }

.combination-links .link-extern {
  background: yellow; }

.combination-links .link-download {
  background: magenta; }

.module-text {
  padding: 20px; }
  .module-text .module-text-content p {
    margin-left: 20px; }
  @media (min-width: 1024px) {
    .module-text {
      padding: 30px 20px; }
      .module-text .module-text-title {
        padding-bottom: 70px; }
      .module-text .module-text-content {
        font-size: 20px;
        line-height: 32px; }
        .module-text .module-text-content p {
          margin-left: 0; } }

.module-image {
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-image.container-full .image-wrapper {
    position: relative;
    left: -50px;
    width: 100vw;
    max-width: 1920px; }
    @media (min-width: 1024px) {
      .module-image.container-full .image-wrapper {
        left: -100px; } }
    @media only screen and (min-width: 2120px) {
      .module-image.container-full .image-wrapper {
        left: 0; } }
  @media (min-width: 1024px) {
    .module-image {
      padding-top: 30px;
      padding-bottom: 30px; } }

.module-teaser {
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-teaser .teaser-headline {
    padding-bottom: 20px; }
  .module-teaser .teaser-box {
    display: block; }
    .module-teaser .teaser-box:hover {
      text-decoration: none; }
  .module-teaser .teaser-content-text {
    padding-bottom: 40px; }
  .module-teaser .subheadline {
    padding-right: 50px; }
  .module-teaser .page-link {
    position: absolute;
    top: 0;
    right: 10px; }
  .module-teaser .subtext {
    padding-top: 30px;
    padding-left: 20px; }
  .module-teaser .image-wrapper {
    float: right;
    width: calc(100% - 20px);
    margin-bottom: 20px;
    padding-bottom: 0; }
  .module-teaser .particles-wrapper {
    position: absolute;
    left: 10px;
    bottom: 0;
    height: calc(100% - 20px);
    width: calc(100% - 40px);
    opacity: 0.1; }
  @media (min-width: 1024px) {
    .module-teaser {
      padding-top: 30px;
      padding-bottom: 30px; }
      .module-teaser .image-wrapper {
        width: 100%;
        padding-left: 50px;
        margin-bottom: 35px; }
      .module-teaser .particles-wrapper {
        left: 20px;
        height: calc(100% - 30px);
        width: calc(100% - 90px); }
      .module-teaser .teaser-content-image {
        display: inline-block;
        float: none;
        vertical-align: middle;
        margin-bottom: 0; }
      .module-teaser .teaser-content-text {
        display: inline-block;
        float: none;
        padding: 0;
        vertical-align: middle; }
      .module-teaser .teaser-headline {
        padding-top: 0;
        padding-bottom: 60px; }
      .module-teaser .subheadline {
        padding-bottom: 25px;
        padding-right: 0; }
        .module-teaser .subheadline span {
          vertical-align: middle; }
      .module-teaser .page-link {
        position: relative;
        right: 0; }
      .module-teaser .subtext {
        display: block;
        padding-top: 0;
        padding-left: 50px; }
      .module-teaser .two-boxes {
        float: left; }
        .module-teaser .two-boxes .teaser-box {
          display: inline-block;
          width: 50%;
          vertical-align: top;
          margin: 0 -2px;
          padding: 0 50px; }
        .module-teaser .two-boxes .teaser-content-image {
          width: 100%;
          padding-left: 0;
          padding-right: 0; }
        .module-teaser .two-boxes .teaser-content-text {
          width: 100%;
          margin-left: 0; }
        .module-teaser .two-boxes .subheadline {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 25px;
          padding-right: 60px; }
        .module-teaser .two-boxes .page-link {
          position: absolute;
          top: 14px;
          right: 10px; } }

.module-opening-hours {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-opening-hours h2 {
    padding-bottom: 15px; }
    @media (min-width: 420px) {
      .module-opening-hours h2 {
        padding-bottom: 60px; } }
  .module-opening-hours p, .module-opening-hours span {
    font-family: "NoeText-Book", serif;
    font-size: 16px; }
    @media (min-width: 420px) {
      .module-opening-hours p, .module-opening-hours span {
        font-size: 20px; } }
  .module-opening-hours .wrapper {
    padding-left: 20px;
    position: relative; }
    @media (min-width: 420px) {
      .module-opening-hours .wrapper {
        padding-left: 2%; } }
    @media (min-width: 1024px) {
      .module-opening-hours .wrapper {
        padding-left: 50px; } }
  .module-opening-hours .additional-info {
    float: left;
    padding-top: 20px;
    font-size: 18px;
    line-height: 22px;
    font-family: "NoeText-Book", serif; }
  .module-opening-hours .module--weekday {
    width: 100%;
    border: 1px solid white;
    border-bottom: none;
    height: 60px;
    position: relative;
    padding-top: 18px;
    padding-left: 18px;
    background-color: #000; }
    .module-opening-hours .module--weekday:first-child {
      margin-left: -3px; }
    .module-opening-hours .module--weekday:nth-child(n + 2) {
      margin-left: -3px; }
    .module-opening-hours .module--weekday:last-child {
      border: 1px solid #FFF; }
    .module-opening-hours .module--weekday .time {
      position: absolute;
      top: 12px;
      border-color: #FFF;
      border-style: solid;
      border-width: 1px;
      z-index: 2;
      background-color: #000;
      width: 56%;
      height: 48px; }
      .module-opening-hours .module--weekday .time.no {
        left: 40%;
        display: flex;
        justify-content: space-evenly;
        height: 36px; }
      .module-opening-hours .module--weekday .time.closed {
        border: none;
        left: 40%;
        top: 12px;
        height: 36px; }
        .module-opening-hours .module--weekday .time.closed svg {
          width: 100%;
          height: 100%;
          transform: scaleX(1.2); }
      .module-opening-hours .module--weekday .time.yes {
        padding-top: 5px;
        padding-left: 12px;
        padding-right: 12px; }
        .module-opening-hours .module--weekday .time.yes.start {
          left: 40%;
          border-bottom: none; }
        .module-opening-hours .module--weekday .time.yes.continue {
          left: 40%;
          border-top: none;
          border-bottom: none;
          height: 60px;
          top: 0; }
          .module-opening-hours .module--weekday .time.yes.continue.arrow {
            text-align: left; }
            .module-opening-hours .module--weekday .time.yes.continue.arrow svg {
              position: relative;
              top: -14px;
              margin-left: 50%;
              transform: translateX(-50%) rotate(90deg); }
          .module-opening-hours .module--weekday .time.yes.continue.fixed {
            text-align: center; }
            .module-opening-hours .module--weekday .time.yes.continue.fixed span {
              position: absolute;
              top: 20%; }
        .module-opening-hours .module--weekday .time.yes.end {
          text-align: right;
          left: 40%;
          border-top: none;
          top: 0;
          padding-top: 20px; }
          .module-opening-hours .module--weekday .time.yes.end.arrow svg {
            position: absolute;
            left: -10px;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
            top: 11px;
            top: -4px; }
        .module-opening-hours .module--weekday .time.yes.start.end {
          left: 40%;
          border: 1px solid;
          display: block;
          top: 12px;
          height: 36px; }
      .module-opening-hours .module--weekday .time.no, .module-opening-hours .module--weekday .time.yes.start.end {
        padding-top: 5px; }
        .module-opening-hours .module--weekday .time.no span, .module-opening-hours .module--weekday .time.yes.start.end span {
          position: absolute; }
          .module-opening-hours .module--weekday .time.no span:nth-child(1), .module-opening-hours .module--weekday .time.yes.start.end span:nth-child(1) {
            left: 8%; }
          .module-opening-hours .module--weekday .time.no span:nth-child(2), .module-opening-hours .module--weekday .time.yes.start.end span:nth-child(2) {
            left: 40%; }
          .module-opening-hours .module--weekday .time.no span:nth-child(3), .module-opening-hours .module--weekday .time.yes.start.end span:nth-child(3) {
            right: 8%; }
  @media (min-width: 420px) {
    .module-opening-hours .additional-info {
      padding-left: 50px;
      font-size: 20px;
      line-height: 32px; }
    .module-opening-hours .module--weekday {
      float: left;
      width: 14%;
      height: 140px;
      padding-top: 18px;
      border-bottom: 1px solid #FFF; }
      .module-opening-hours .module--weekday .time {
        top: 73px !important;
        padding-top: 5px;
        height: 36px;
        left: 8%; }
        .module-opening-hours .module--weekday .time.no {
          width: 84%;
          left: 8%;
          display: flex;
          padding-left: 10px;
          padding-right: 10px;
          justify-content: space-between; }
        .module-opening-hours .module--weekday .time.closed {
          border: none;
          left: 8%;
          width: 84%;
          padding-top: 0; }
          .module-opening-hours .module--weekday .time.closed svg {
            transform: scaleX(1); }
        .module-opening-hours .module--weekday .time.yes.start {
          width: 94%;
          left: 8%;
          padding-left: 10px;
          border-bottom: 1px solid; }
        .module-opening-hours .module--weekday .time.yes.continue {
          left: 0;
          width: 102%;
          border-right: none;
          border-left: none;
          border-top: 1px solid;
          border-bottom: 1px solid;
          height: 36px; }
          .module-opening-hours .module--weekday .time.yes.continue.arrow {
            text-align: left; }
            .module-opening-hours .module--weekday .time.yes.continue.arrow span {
              font-size: 1rem; }
            .module-opening-hours .module--weekday .time.yes.continue.arrow svg {
              top: 4px;
              margin-left: -18px;
              transform: rotate(0); }
          .module-opening-hours .module--weekday .time.yes.continue.fixed {
            text-align: center; }
        .module-opening-hours .module--weekday .time.yes.end {
          text-align: right;
          left: 0;
          border-left: none;
          padding-right: 10px;
          height: 36px;
          width: 92%;
          border-top: 1px solid;
          padding-top: 5px; }
          .module-opening-hours .module--weekday .time.yes.end.arrow svg {
            position: absolute;
            left: -10px;
            top: 11px; }
        .module-opening-hours .module--weekday .time.yes.start.end {
          width: 84%;
          left: 8%;
          display: flex;
          justify-content: space-between; }
        .module-opening-hours .module--weekday .time.no span, .module-opening-hours .module--weekday .time.yes.start.end span {
          position: relative;
          left: 0 !important; } }
  @media (min-width: 1024px) {
    .module-opening-hours {
      padding-top: 200px;
      padding-bottom: 200px; }
      .module-opening-hours .module--weekday .time.yes.continue.arrow svg {
        top: 0;
        margin-left: -20px; }
      .module-opening-hours .module--weekday .time.yes.end.arrow svg {
        left: -7px;
        top: 14px;
        transform: none; }
      .module-opening-hours .module--weekday .time.no.arrow svg {
        position: relative;
        top: -2px; } }

.module-article {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-article .mobile-hidden {
    display: none; }
  .module-article .article-headline {
    padding-bottom: 30px; }
  .module-article .article-image-wrapper {
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .module-article .article-image-wrapper .inner-image.full-width {
        width: 100vw;
        max-width: 1920px;
        position: relative;
        right: 40px; } }
    @media (min-width: 1024px) {
      .module-article .article-image-wrapper .inner-image.full-width {
        right: -90px; } }
    @media only screen and (min-width: 2120px) {
      .module-article .article-image-wrapper .inner-image.full-width {
        right: 0; } }
  .module-article .article-text {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    background-color: #000; }
    .module-article .article-text p {
      font-family: "NoeText-Book", serif;
      font-size: 16px;
      line-height: 26px; }
  .module-article .article-download {
    background-color: #000; }
  .module-article .download-box {
    position: relative;
    display: block;
    border: 1px solid #FFF;
    border-bottom: none;
    background-color: #000;
    padding: 20px 50px 20px 20px;
    margin: 0 10px; }
    .module-article .download-box:last-child {
      border-bottom: 1px solid #FFF; }
    .module-article .download-box:hover {
      text-decoration: none; }
      .module-article .download-box:hover p:first-child {
        text-decoration: underline; }
    .module-article .download-box p {
      padding: 0; }
    .module-article .download-box .download-size {
      color: #4D4D4D;
      font-size: 16px;
      line-height: 16px; }
    .module-article .download-box .icon-download {
      position: absolute;
      bottom: 5px;
      right: 5px; }
  @media (min-width: 1024px) {
    .module-article {
      padding-top: 30px;
      padding-bottom: 30px; }
      .module-article .mobile-hidden {
        display: block; }
      .module-article .desktop-hidden {
        display: none; }
      .module-article .desktop-to-left {
        margin-left: -100px; }
      .module-article .article-headline {
        padding-bottom: 60px;
        padding-left: 120px;
        background: #000;
        z-index: 1; }
      .module-article .article-image-wrapper {
        float: right; }
        .module-article .article-image-wrapper .inner-image {
          position: relative;
          float: right;
          right: -100px;
          width: 140%;
          max-width: 1920px; } }
      @media only screen and (min-width: 1024px) and (min-width: 2120px) {
        .module-article .article-image-wrapper .inner-image {
          right: 0; } }
  @media (min-width: 1024px) {
      .module-article .article-text {
        padding-top: 0;
        padding-left: 160px;
        padding-right: 50px; }
        .module-article .article-text p {
          font-size: 20px;
          line-height: 32px; }
      .module-article .article-download {
        padding-left: 150px;
        padding-right: 50px; }
      .module-article .download-box {
        display: inline-block;
        margin: 0 -2px;
        margin-top: -1px;
        padding-right: 20px;
        padding-bottom: 50px;
        border-bottom: 1px solid #FFF; }
        .module-article .download-box:first-child {
          margin-left: -3px; }
        .module-article .download-box:nth-child(n + 2) {
          margin-left: -3px; }
        .module-article .download-box:last-child {
          border: 1px solid #FFF; }
        .module-article .download-box p:first-child {
          font-size: 20px;
          line-height: 20px;
          padding-bottom: 10px; } }

.module-person {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-person .mobile-hidden {
    display: none; }
  .module-person .person-headline {
    padding-bottom: 30px; }
  .module-person .person-image-wrapper {
    padding: 0;
    margin-bottom: 30px; }
  .module-person .person-name {
    line-height: 24px;
    padding-bottom: 5px; }
    .module-person .person-name h5 {
      font-weight: normal; }
  .module-person .person-text {
    padding-bottom: 20px;
    padding-left: 30px; }
    .module-person .person-text p {
      font-family: "NoeText-Book", serif;
      font-size: 16px;
      line-height: 26px; }
  @media (min-width: 1024px) {
    .module-person {
      padding-top: 30px;
      padding-bottom: 30px; }
      .module-person .mobile-hidden {
        display: block; }
      .module-person .desktop-hidden {
        display: none; }
      .module-person .person-wrapper {
        background: none; }
      .module-person .person-headline {
        background: #000;
        z-index: 1; }
      .module-person .person-image-wrapper {
        float: right;
        margin-bottom: 0; }
        .module-person .person-image-wrapper .inner-image {
          position: relative;
          float: right;
          width: 180%;
          right: -100px;
          max-width: 1920px; } }
      @media only screen and (min-width: 1024px) and (min-width: 2020px) {
        .module-person .person-image-wrapper .inner-image {
          right: 0; } }
  @media (min-width: 1024px) {
      .module-person .person-name {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 15px;
        background: #000;
        z-index: 1; }
        .module-person .person-name p {
          font-family: "NoeText-Book", serif;
          font-size: 20px;
          line-height: 32px; }
      .module-person .person-text {
        padding-top: 0;
        padding-bottom: 150px;
        padding-left: 50px;
        padding-right: 50px;
        background: #000;
        z-index: 1; }
        .module-person .person-text p {
          font-size: 20px;
          line-height: 32px; }
      .module-person .person-signature-wrapper {
        padding: 0;
        background: none;
        z-index: 1;
        pointer-events: none; }
        .module-person .person-signature-wrapper .ratio-auto {
          bottom: 120px;
          background: transparent; } }

.module-vendor-list {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-vendor-list .vendor-list-headline {
    padding-bottom: 20px; }
  .module-vendor-list .vendor-list-text {
    padding-bottom: 35px; }
    .module-vendor-list .vendor-list-text p {
      font-family: "NoeText-Book", serif;
      font-size: 16px;
      line-height: 26px; }
  .module-vendor-list .link-box {
    position: relative;
    display: block;
    border: 1px solid #FFF;
    background-color: #000;
    padding: 20px 50px 20px 20px; }
    .module-vendor-list .link-box:nth-child(n + 2) {
      border-top: none; }
    .module-vendor-list .link-box:hover {
      text-decoration: none; }
      .module-vendor-list .link-box:hover p:first-child {
        text-decoration: underline; }
      .module-vendor-list .link-box:hover .icon-link svg {
        left: calc(50% + 10px);
        top: calc(50% - 10px); }
    .module-vendor-list .link-box p {
      padding: 0; }
    .module-vendor-list .link-box .link-subtitle {
      color: #4D4D4D;
      font-size: 16px;
      line-height: 16px; }
    .module-vendor-list .link-box .icon-link {
      position: absolute;
      bottom: 5px;
      right: 5px; }
  @media (min-width: 1024px) {
    .module-vendor-list {
      padding-top: 30px;
      padding-bottom: 30px; }
      .module-vendor-list .vendor-list-headline {
        padding-bottom: 45px;
        background: #000;
        z-index: 1; }
      .module-vendor-list .vendor-list-text {
        padding-left: 50px;
        padding-bottom: 45px; }
        .module-vendor-list .vendor-list-text p {
          font-size: 20px;
          line-height: 32px; }
      .module-vendor-list .vendor-list-download {
        padding-left: 50px;
        padding-right: 50px; }
      .module-vendor-list .vendor-list-link {
        padding-left: 50px;
        padding-right: 50px; }
      .module-vendor-list .link-box {
        display: inline-block;
        width: 33.33%;
        min-height: 140px;
        margin: 0 -2px;
        padding-right: 20px;
        padding-bottom: 50px;
        vertical-align: top;
        border: none;
        border-top: 1px solid #FFF;
        border-left: 1px solid #FFF; }
        .module-vendor-list .link-box:nth-child(n + 2) {
          border-top: 1px solid #FFF; }
        .module-vendor-list .link-box:nth-child(3n + 3) {
          border-right: 1px solid #FFF; }
        .module-vendor-list .link-box:last-child {
          border-bottom: 1px solid #FFF;
          border-right: 1px solid #FFF; }
        .module-vendor-list .link-box:nth-last-child(2) {
          border-bottom: 1px solid #FFF; }
        .module-vendor-list .link-box:nth-last-child(3) {
          border-bottom: 1px solid #FFF; }
        .module-vendor-list .link-box p:first-child {
          font-size: 20px;
          line-height: 20px;
          padding-bottom: 10px; } }

.module-favourite-box {
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-favourite-box canvas {
    display: none; }
  @media (min-width: 1024px) {
    .module-favourite-box canvas {
      display: block; } }
  .module-favourite-box:hover a {
    text-decoration: none; }
  .module-favourite-box .favourite-box {
    display: block; }
  .module-favourite-box .favourite-box-image-wrapper {
    position: relative;
    padding-bottom: 65px; }
  .module-favourite-box .favourite-box-subheadline {
    display: none; }
  .module-favourite-box .button-wrapper {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%); }
  .module-favourite-box .button-inner-wrapper {
    padding-bottom: 0; }
  @media (min-width: 1024px) {
    .module-favourite-box {
      padding-top: 30px;
      padding-bottom: 30px; }
      .module-favourite-box .favourite-box {
        padding-left: 50px;
        padding-right: 50px; }
      .module-favourite-box .favourite-box-image-wrapper {
        padding-bottom: 0; }
        .module-favourite-box .favourite-box-image-wrapper .inner-image {
          padding-bottom: 25px;
          padding-left: 50px;
          padding-right: 50px; }
        .module-favourite-box .favourite-box-image-wrapper .particles-wrapper {
          position: absolute;
          top: 120px;
          left: -50px;
          height: calc(100% - 120px);
          width: calc(100% - 65px);
          background-color: #000;
          opacity: 0.1;
          z-index: 1; }
      .module-favourite-box .favourite-box-subheadline {
        display: block;
        padding-top: 10px;
        padding-right: 50px; }
      .module-favourite-box .button-wrapper {
        left: auto;
        bottom: -15px;
        right: -60px;
        transform: none;
        z-index: 3; } }

.module-beer-product {
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-beer-product .beer-product-title-text-wrapper {
    padding-bottom: 25px; }
  .module-beer-product .beer-product-title .title {
    font-family: "NoeText-Bold", serif; }
  .module-beer-product .beer-product-text {
    padding-left: 50px; }
  .module-beer-product.is-visible .beer-product-images-wrapper .image-wrapper:first-child {
    transform: scale(1);
    opacity: 1; }
  .module-beer-product.is-visible .beer-product-images-wrapper .image-wrapper:nth-child(2n) {
    transition: transform 1s 0.1s ease, opacity 0.4s 0.2s ease;
    transform: scale(0.7);
    opacity: 0.3; }
  .module-beer-product .beer-product-images-wrapper {
    position: relative;
    padding-bottom: 15px; }
    .module-beer-product .beer-product-images-wrapper .image-wrapper .image {
      background: transparent; }
      .module-beer-product .beer-product-images-wrapper .image-wrapper .image img {
        max-width: 150px; }
        @media (min-width: 420px) {
          .module-beer-product .beer-product-images-wrapper .image-wrapper .image img {
            max-width: 200px; } }
        @media (min-width: 1024px) {
          .module-beer-product .beer-product-images-wrapper .image-wrapper .image img {
            max-width: 25vh; } }
    .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child {
      position: relative;
      z-index: 2;
      opacity: 0;
      transform: scale(0.9);
      transition: transform 1s ease, opacity 0.4s 0.1s ease; }
      .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child .image {
        transform: rotate(-10deg);
        background: transparent; }
        .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child .image img {
          max-width: 150px; }
          @media (min-width: 420px) {
            .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child .image img {
              max-width: 200px; } }
          @media (min-width: 1024px) {
            .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child .image img {
              max-width: 25vh; } }
      @media (min-width: 420px) {
        .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child {
          left: 20%; } }
    .module-beer-product .beer-product-images-wrapper .image-wrapper:nth-child(2n) {
      position: absolute;
      top: -5%;
      left: 30%;
      width: 100%;
      z-index: 1;
      transform: scale(0.6);
      opacity: 0;
      transition: transform 1s ease, opacity 0.4s 0.1s ease; }
      .module-beer-product .beer-product-images-wrapper .image-wrapper:nth-child(2n) .image {
        transform: rotate(10deg); }
      @media (min-width: 420px) {
        .module-beer-product .beer-product-images-wrapper .image-wrapper:nth-child(2n) {
          left: 40%; } }
  .module-beer-product .list-item {
    padding-bottom: 35px; }
    .module-beer-product .list-item .title {
      padding-bottom: 20px; }
    .module-beer-product .list-item .sublist-item {
      display: block;
      padding: 15px 20px;
      border: 1px solid #FFF;
      border-bottom: none; }
      .module-beer-product .list-item .sublist-item:last-child {
        border-bottom: 1px solid #FFF; }
      .module-beer-product .list-item .sublist-item p {
        padding-bottom: 0; }
  @media (min-width: 1024px) {
    .module-beer-product {
      padding-top: 60px;
      padding-bottom: 60px; }
      .module-beer-product .beer-product-title-text-wrapper {
        padding-top: 33vh;
        padding-bottom: 33vh; }
      .module-beer-product .beer-product-title {
        padding-bottom: 35px; }
      .module-beer-product .beer-product-text {
        padding-left: 50px; }
      .module-beer-product .beer-product-images-wrapper {
        float: right;
        width: 40%;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        margin-right: 5%;
        height: 100vh; }
        .module-beer-product .beer-product-images-wrapper .image-wrapper:first-child {
          left: -12vh; }
        .module-beer-product .beer-product-images-wrapper .image-wrapper:nth-child(2n) {
          top: 7vh;
          left: 12vh; }
        .module-beer-product .beer-product-images-wrapper .image-wrapper img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%); }
      .module-beer-product .beer-product-list {
        margin-right: 20px;
        padding-bottom: 33vh; }
      .module-beer-product .list-item {
        padding-bottom: 25px; }
        .module-beer-product .list-item .sublist-wrapper {
          padding-left: 50px;
          padding-right: 50px; }
        .module-beer-product .list-item .sublist-item {
          position: relative;
          display: inline-block;
          margin: 0 -2px;
          margin-top: -1px;
          padding-bottom: 12px;
          border: 1px solid #FFF;
          background-color: #000; }
          .module-beer-product .list-item .sublist-item:first-child {
            margin-left: -3px; }
          .module-beer-product .list-item .sublist-item:nth-child(n + 2) {
            margin-left: -3px; }
          .module-beer-product .list-item .sublist-item:last-child {
            border: 1px solid #FFF; }
          .module-beer-product .list-item .sublist-item p {
            font-size: 20px;
            line-height: 20px; } }

.module-recommend-box {
  padding-top: 20px;
  padding-bottom: 20px; }
  .module-recommend-box h3 {
    padding-bottom: 30px; }
  .module-recommend-box h4 {
    margin-left: -10px;
    padding-top: 11px; }
  .module-recommend-box .module--images-wrapper {
    padding-left: 0.625rem; }
  .module-recommend-box .images-wrapper--image {
    margin-bottom: 0px; }
  .module-recommend-box .inner-images-wrapper {
    margin-bottom: 20px; }
  @media (min-width: 420px) {
    .module-recommend-box h3 {
      padding-bottom: 40px; }
    .module-recommend-box .module--images-wrapper {
      padding-left: 0; }
    .module-recommend-box .images-wrapper--image {
      margin-bottom: 0; }
    .module-recommend-box h4 {
      margin-left: 0px;
      padding-top: 15px; } }
  @media (min-width: 1024px) {
    .module-recommend-box {
      padding-top: 30px;
      padding-bottom: 30px; }
      .module-recommend-box .inner-images-wrapper {
        margin-bottom: 60px; }
      .module-recommend-box .images-wrapper--image {
        padding-bottom: 25px;
        padding-left: 25px; }
      .module-recommend-box h4 {
        padding-left: 25px; } }

.module-gallery {
  position: relative;
  padding: 20px 10px; }
  .module-gallery.cursor-left {
    cursor: url("../../assets/images/arrow-l.svg"), auto; }
  .module-gallery.cursor-right {
    cursor: url("../../assets/images/arrow-r.svg"), auto; }
  .module-gallery .gallery-cards {
    position: absolute;
    width: 80%;
    top: 200px;
    left: 40px;
    height: 60px;
    overflow-y: hidden; }
    .module-gallery .gallery-cards .gallery-cards--items {
      width: 70px;
      height: 100%;
      position: absolute;
      bottom: 0;
      background-color: #000;
      transition: all 300ms ease-in-out;
      text-align: right;
      border-top: 1px solid #FFF;
      border-right: 1px solid #FFF;
      text-align: center; }
      .module-gallery .gallery-cards .gallery-cards--items p {
        font-size: 20px;
        line-height: 20px;
        padding: 20px; }
      .module-gallery .gallery-cards .gallery-cards--items.active {
        opacity: 1;
        border-left: 1px solid #FFF; }
  .module-gallery .gallery--wrapper {
    background-color: #000; }
    .module-gallery .gallery--wrapper .gallery-items.transition h1, .module-gallery .gallery--wrapper .gallery-items.transition h2, .module-gallery .gallery--wrapper .gallery-items.transition p {
      transition: all 0.2s ease-in-out; }
    .module-gallery .gallery--wrapper .gallery-items.transition img {
      transition: all 0.4s ease; }
    .module-gallery .gallery--wrapper.right .gallery-items h1, .module-gallery .gallery--wrapper.right .gallery-items h2, .module-gallery .gallery--wrapper.right .gallery-items p {
      transform: translateX(-60px); }
    .module-gallery .gallery--wrapper.right .gallery-items img {
      transform: translateX(-100px); }
    .module-gallery .gallery--wrapper.right .gallery-items.slick-active h1, .module-gallery .gallery--wrapper.right .gallery-items.slick-active h2, .module-gallery .gallery--wrapper.right .gallery-items.slick-active p, .module-gallery .gallery--wrapper.right .gallery-items.slick-active img {
      transform: translateX(0);
      opacity: 1; }
    .module-gallery .gallery--wrapper.left .gallery-items h1, .module-gallery .gallery--wrapper.left .gallery-items h2, .module-gallery .gallery--wrapper.left .gallery-items p {
      transform: translateX(60px); }
    .module-gallery .gallery--wrapper.left .gallery-items img {
      transform: translateX(100px); }
    .module-gallery .gallery--wrapper.left .gallery-items.slick-active h1, .module-gallery .gallery--wrapper.left .gallery-items.slick-active h2, .module-gallery .gallery--wrapper.left .gallery-items.slick-active p, .module-gallery .gallery--wrapper.left .gallery-items.slick-active img {
      transform: translateX(0);
      opacity: 1; }
  .module-gallery .gallery--image {
    padding-right: 0;
    padding-left: 0;
    max-height: 240px;
    overflow: hidden; }
  .module-gallery .gallery-text {
    padding: 30px 20px 0; }
    .module-gallery .gallery-text .title-wrapper {
      position: relative;
      margin-bottom: 20px; }
    .module-gallery .gallery-text h2 {
      padding-right: 60px;
      padding-top: 10px;
      padding-bottom: 5px; }
    .module-gallery .gallery-text .icon-arrow-link {
      position: absolute;
      top: 50%;
      right: 0;
      width: 50px;
      height: 50px;
      transform: translateY(-50%); }
      .module-gallery .gallery-text .icon-arrow-link svg {
        width: 45px;
        height: 25px; }
    .module-gallery .gallery-text .text-outer-wrapper {
      position: relative;
      padding-bottom: 50px; }
      .module-gallery .gallery-text .text-outer-wrapper.no-bottom-padding {
        transition: all 0.2s ease-in;
        padding-bottom: 0; }
    .module-gallery .gallery-text .text-wrapper {
      position: relative;
      padding-left: 20px;
      max-height: 212px;
      overflow: hidden; }
      .module-gallery .gallery-text .text-wrapper:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 64px;
        background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)); }
      .module-gallery .gallery-text .text-wrapper.max-height-transition {
        transition: all 1s ease-in;
        max-height: 2000px; }
        .module-gallery .gallery-text .text-wrapper.max-height-transition:before {
          display: none; }
      .module-gallery .gallery-text .text-wrapper.old-transition {
        transition: all 100ms ease-in-out; }
      .module-gallery .gallery-text .text-wrapper h1 {
        font-family: "NoeText-Bold", serif;
        word-break: break-word;
        hyphens: auto; }
    .module-gallery .gallery-text .icon-close {
      position: absolute;
      transform: rotate(45deg);
      transition: all 1s; }
      .module-gallery .gallery-text .icon-close.disappear {
        transform: rotate(450deg);
        opacity: 0; }
    .module-gallery .gallery-text .box-wrapper {
      padding-left: 20px; }
    .module-gallery .gallery-text .download-box {
      position: relative;
      display: block;
      border: 1px solid #FFF;
      background-color: #000;
      padding: 20px 50px 20px 20px;
      margin-top: 20px; }
      .module-gallery .gallery-text .download-box:hover {
        text-decoration: none; }
        .module-gallery .gallery-text .download-box:hover p:first-child {
          text-decoration: underline; }
      .module-gallery .gallery-text .download-box p {
        padding: 0; }
      .module-gallery .gallery-text .download-box .download-size {
        color: #4D4D4D;
        font-size: 16px;
        line-height: 16px; }
      .module-gallery .gallery-text .download-box .icon-download {
        position: absolute;
        bottom: 5px;
        right: 5px; }
  @media (min-width: 1024px) {
    .module-gallery {
      padding-top: 100px;
      padding-bottom: 30px; }
      .module-gallery .gallery-cards {
        top: 30px !important;
        width: calc(100% - 60px);
        height: 70px;
        left: 60px;
        text-align: left; }
        .module-gallery .gallery-cards .gallery-cards--items {
          width: calc(50% - 30px);
          text-align: left;
          margin-left: 1px; }
          .module-gallery .gallery-cards .gallery-cards--items p {
            font-family: "NoeText-Book", serif; }
      .module-gallery .gallery--image {
        float: right;
        padding-right: 20px;
        padding-left: 20px;
        z-index: 1;
        max-height: none;
        overflow: visible; }
        .module-gallery .gallery--image .inner-image-wrapper {
          float: right;
          width: 120%;
          padding-bottom: 0; }
      .module-gallery .gallery-text {
        padding-left: 10px;
        padding-right: 0;
        margin-bottom: 70px;
        background-color: #000;
        z-index: 2; }
        .module-gallery .gallery-text h2 {
          padding-right: 0;
          padding-top: 0;
          padding-bottom: 40px; }
        .module-gallery .gallery-text .icon-arrow-link {
          display: none; }
        .module-gallery .gallery-text .title-wrapper {
          margin-bottom: 0; }
        .module-gallery .gallery-text .text-wrapper {
          padding-bottom: 115px;
          padding-left: 0;
          padding-right: 50px;
          max-height: none; }
          .module-gallery .gallery-text .text-wrapper:before {
            display: none; }
          .module-gallery .gallery-text .text-wrapper h1 {
            line-height: 110%;
            padding-bottom: 30px; }
          .module-gallery .gallery-text .text-wrapper p {
            padding-left: 50px;
            font-family: "NoeText-Book", serif;
            font-size: 20px;
            line-height: 32px; }
        .module-gallery .gallery-text .icon-close {
          display: none; }
        .module-gallery .gallery-text .box-wrapper {
          position: absolute;
          bottom: -50px;
          left: 60px;
          padding: 0; }
        .module-gallery .gallery-text .download-box {
          display: inline-block;
          margin: 0 -2px;
          padding-right: 20px;
          padding-bottom: 50px; }
          .module-gallery .gallery-text .download-box p:first-child {
            font-size: 20px;
            line-height: 20px;
            padding-bottom: 10px; } }

.module-headline {
  padding: 20px; }
  .module-headline .backbutton-text-wrapper {
    margin-bottom: 30px; }
    .module-headline .backbutton-text-wrapper:hover {
      text-decoration: none; }
      .module-headline .backbutton-text-wrapper:hover .icon {
        left: 0; }
    .module-headline .backbutton-text-wrapper .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%) rotate(180deg);
      transition: all 0.3s; }
    .module-headline .backbutton-text-wrapper .backbutton-text {
      padding-left: 40px;
      font-size: 40px;
      line-height: 40px; }
  @media (min-width: 1024px) {
    .module-headline {
      padding: 30px 20px; }
      .module-headline .backbutton-text-wrapper:hover .icon {
        left: -10px; }
      .module-headline .backbutton-text-wrapper .icon {
        left: 0; } }

.module-popup {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  /* Modal Content/Box */ }
  .module-popup .popup-content {
    position: relative;
    background-color: #000;
    padding: 40px 40px 20px 40px;
    border: 1px solid #FFF;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (min-width: 1024px) {
      .module-popup .popup-content {
        width: 50%; } }
    .module-popup .popup-content .popup-text {
      box-sizing: border-box;
      text-align: center; }
    .module-popup .popup-content .close {
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      display: block;
      width: 40px;
      height: 40px;
      color: #FFF;
      font-size: 2em;
      font-weight: bold; }
      .module-popup .popup-content .close:hover, .module-popup .popup-content .close:focus {
        text-decoration: none;
        cursor: pointer; }

.container-fluid {
  position: relative;
  overflow: visible; }
  .container-fluid .beer-law-cont {
    padding-top: 6rem;
    padding-bottom: 3rem; }
    .container-fluid .beer-law-cont .beer-law-title {
      padding-top: 5rem; }
    .container-fluid .beer-law-cont .beer-law-text {
      padding-top: 10rem; }
  .container-fluid .bubles-cont {
    position: absolute;
    width: 100vw;
    height: 100vh; }

@keyframes povecalo {
  from {
    transform: scale(0.2); }
  to {
    transform: scale(1); } }
    .container-fluid .bubles-cont .beer-bubble {
      position: absolute;
      z-index: 0; }
    .container-fluid .bubles-cont .bb-small {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      position: absolute;
      box-shadow: 10px -10px 5px #fff; }
    .container-fluid .bubles-cont .bb-1 {
      transform: rotate(-30deg);
      top: 40%;
      left: 60%;
      animation: bb-1anim 15s ease-in-out infinite; }

@keyframes bb-1anim {
  from {
    transform: rotate(-30deg) translateX(0px); }
  25% {
    transform: rotate(-30deg) translateX(20px); }
  50% {
    transform: rotate(-30deg) translateY(10px); }
  75% {
    transform: rotate(-30deg) translateX(20px); }
  to {
    transform: rotate(-30deg) translateX(0px); } }
    .container-fluid .bubles-cont .bb-2 {
      top: -20%;
      left: 70%;
      animation: bb-2anim 10s ease-in-out infinite; }

@keyframes bb-2anim {
  from {
    transform: scale(0.2) rotate(-60deg) translateX(0px); }
  25% {
    transform: scale(0.2) rotate(-60deg) translateX(-40px); }
  50% {
    transform: scale(0.2) rotate(-60deg) translateY(20px); }
  75% {
    transform: scale(0.2) rotate(-60deg) translateX(-40px); }
  to {
    transform: scale(0.2) rotate(-60deg) translateX(0px); } }
    .container-fluid .bubles-cont .bb-3 {
      transform: scale(0.1);
      top: -33%;
      left: 40%;
      animation: bb-3anim 8s ease-in-out infinite; }

@keyframes bb-3anim {
  from {
    transform: scale(0.1) translateX(0px); }
  25% {
    transform: scale(0.1) translateX(-100px); }
  50% {
    transform: scale(0.1) translateY(50px); }
  75% {
    transform: scale(0.1) translateX(-100px); }
  to {
    transform: scale(0.1) translateX(0px); } }
    .container-fluid .bubles-cont .bb-4 {
      transform: scale(0.4) rotate(10deg);
      top: 20%;
      left: 10%;
      animation: bb-4anim 10s ease-in-out infinite; }

@keyframes bb-4anim {
  from {
    transform: scale(0.4) rotate(10deg) translateX(0px); }
  25% {
    transform: scale(0.4) rotate(10deg) translateX(-30px); }
  50% {
    transform: scale(0.4) rotate(10deg) translateY(15px); }
  75% {
    transform: scale(0.4) rotate(10deg) translateX(-30px); }
  to {
    transform: scale(0.4) rotate(10deg) translateX(0px); } }
    .container-fluid .bubles-cont .bb-5 {
      transform: scale(0.1);
      top: -25%;
      left: 12%;
      animation: bb-5anim 10s ease-in-out infinite; }

@keyframes bb-5anim {
  from {
    transform: scale(0.1) translateX(0px); }
  25% {
    transform: scale(0.1) translateX(-100px); }
  50% {
    transform: scale(0.1) translateY(50px); }
  75% {
    transform: scale(0.1) translateX(-100px); }
  to {
    transform: scale(0.1) translateX(0px); } }
    .container-fluid .bubles-cont .bb-6 {
      transform: scale(0.7) rotate(20deg);
      top: 85%;
      left: 10%;
      animation: bb-6anim 15s ease-in-out infinite; }

@keyframes bb-6anim {
  from {
    transform: scale(0.7) rotate(20deg) translateX(0px); }
  25% {
    transform: scale(0.7) rotate(20deg) translateX(-20px); }
  50% {
    transform: scale(0.7) rotate(20deg) translateY(10px); }
  75% {
    transform: scale(0.7) rotate(20deg) translateX(-20px); }
  to {
    transform: scale(0.7) rotate(20deg) translateX(0px); } }

[data-namespace*="beer-law-detail"] {
  background-image: radial-gradient(circle, #ffdc00 50%, #ffb100 100%); }

.header-bier-law {
  height: auto; }

.main-logo-wrapper {
  padding-top: 30px;
  padding-bottom: 50px; }

.beer-law-bg {
  position: relative;
  min-height: 100vh;
  margin-bottom: 110px; }
  .beer-law-bg .button-wrapper {
    position: absolute;
    bottom: -110px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%); }

.beer-law-title-wrapper {
  margin-top: 100px;
  margin-bottom: 50px; }
  .beer-law-title-wrapper h1 {
    font-size: 40px;
    line-height: 40px;
    word-break: break-word;
    hyphens: auto;
    font-family: "NoeText-Bold", serif; }
  .beer-law-title-wrapper:hover a {
    text-decoration: none; }
  .beer-law-title-wrapper:hover .icon {
    left: 0; }
  .beer-law-title-wrapper .icon-arrow-link {
    display: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%) rotate(180deg);
    transition: all 0.3s; }

.beer-law-text {
  position: relative;
  margin-bottom: 100px;
  max-width: 100vw; }
  @media (min-width: 1024px) {
    .beer-law-text {
      max-width: 80vw; } }
  @media (min-width: 1400px) {
    .beer-law-text {
      max-width: 40vw; } }
  .beer-law-text p {
    color: rgba(255, 255, 255, 0.4);
    transition: all 0.5s ease-in; }
    .beer-law-text p.no-opacity {
      color: #FFF; }
  .beer-law-text strong {
    color: white; }

@media (min-width: 1024px) {
  .beer-law-bg {
    margin-bottom: 0; }
    .beer-law-bg .button-wrapper {
      bottom: 75px;
      left: auto;
      right: 0; }
  .main-logo-wrapper {
    padding-top: 80px;
    padding-bottom: 130px; }
  .beer-law-title-wrapper {
    padding-left: 50px;
    margin-bottom: 230px; }
    .beer-law-title-wrapper h1 {
      font-size: 120px;
      line-height: 80px; }
    .beer-law-title-wrapper .icon-arrow-link {
      display: block; }
  .beer-law-text p {
    color: rgba(255, 255, 255, 0.4);
    padding-bottom: 90px; }
  .beer-law-text strong {
    color: white; } }

.error-title-wrapper {
  margin-bottom: 50px; }
  .error-title-wrapper:hover {
    text-decoration: none; }

.beer-law-title.error-title {
  font-size: 40px;
  line-height: 40px; }

@media (min-width: 1024px) {
  font-size: 80px;
  line-height: 80px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "NoeText-Book";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "NoeText-Book";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.example {
  display: flex; }
  .example div {
    flex: 1; }
